.cpbulba-blackbar{
    position: relative;
    background: $default-color;
    padding: .5rem 1rem;
    border-bottom: 1px solid rgba(#fff, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    ul{
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        li{
            &:not(:last-child){
                margin-right: 1rem;
            }
            a{
                color: #fff;
                &:hover{
                    color: $default-color2;
                }
            }
        }
    }
    @media print{
        display: none;
    }
} 