// settings
@import "settings/mixins";
@import "settings/colors";
@import "settings/fonts";
@import "settings/icons";
@import "settings/typography";
@import "settings/variables";

//plugins
@import "plugins/tooltipster";
@import "plugins/tooltipster--theme";

//print
@page {
    size: A4;
    margin-top: 0.4in;
    margin-bottom: 0.4in;
    margin-left: 0.4in;
    margin-right: 0.4in;    
}

//components
@import "components/blackbar";
.cpbulba-header {
    position: relative;
    background: $default-color;
    display: flex;
    justify-content: space-between;
    height: 3.5em;
    z-index: 9998;
    a {
        font-family: $heading-font-family;
        font-weight: $heading-font-weight;
        text-transform: uppercase;
        font-size: 0.75em;
        color: #fff;
        &:hover,
        &:focus {
            color: $default-color2;
        }
    }
    .logo {
        font-size: 2.5em;
        display: inline-flex;
        align-items: center;
        padding: 0 1rem;
        span {
            display: none;
        }
    }
    .sandwich,
    .search {
        display: inline-flex;
        align-items: center;
        line-height: 1;
        padding: 1rem;
        i {
            font-size: 2em;
        }
        &>*:not(:last-child) {
            margin-right: .5em;
        }
    }
    .sandwich {
        @include media("tablet-wide", min) {
            display: none;
        }
    }
    nav {
        flex: 1;
        display: flex;
        @include media("tablet-wide", max) {
            display: none;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        >ul {
            display: flex;
            >li {
                display: flex;
                >ul {
                    >li {
                        >ul {
                            >li {
                                >a {
                                    display: inline-block;
                                    margin-bottom: 1em;
                                }
                                >ul {
                                    >li {
                                        line-height: 1.2;
                                        &:not(:last-child) {
                                            margin-bottom: .5em;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                >a {
                    display: flex;
                    align-items: center;
                    padding: 0 1rem;
                    &:after {
                        content: '\f2f9';
                        font-family: $zmdi;
                        font-size: 1.5em;
                        margin-left: .25em;
                        opacity: 0.5;
                        transition: all 150ms ease-in-out;
                    }
                }
                &:not(.active) {
                    >ul {
                        display: none;
                    }
                }
                &.active {
                    >a {
                        background: #fff;
                        color: $default-color;
                        &:after {
                            transform: rotate(180deg);
                        }
                    }
                }
                &[data-type="media"],
                &[data-type="apps"] {
                    >ul {
                        position: absolute;
                        box-shadow: 0 1rem 2rem 0 rgba(#000, 0.15);
                        border-bottom-left-radius: 1rem;
                        border-bottom-right-radius: 1rem;
                        overflow: hidden;
                        left: 0;
                        right: 0;
                        top: 3.5em;
                        background: $background-color;
                        padding: 1rem 2rem;
                        a {
                            text-transform: none;
                            color: $text-color;
                            &:hover,
                            &:focus {
                                color: $default-color;
                            }
                        }
                        >li {
                            display: flex;
                            align-items: flex-start;
                            box-sizing: border-box;
                            padding: 1rem 0 0;
                            &:not(:last-child) {
                                border-bottom: 1px solid #ddd;
                            }
                            >a {
                                flex: 0;
                                flex-basis: calc(20% - 3rem);
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding: .5rem .5rem .5rem 1rem;
                                border-radius: 2rem;
                                text-transform: uppercase;
                                cursor: default;
                                pointer-events: none;
                            }
                            >ul {
                                display: flex;
                                flex-wrap: wrap;
                                width: 80%;
                                >li {
                                    flex-basis: 10%;
                                    text-align: center;
                                    padding: 1rem;
                                    line-height: 1;
                                    >a {
                                        font-weight: $base-font-weight;
                                        color: $text-color;
                                        &:before {
                                            font-family: $cptoolbox;
                                            display: block;
                                            font-size: 3em;
                                            margin-bottom: .5rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                @mixin cpbulbaNavAppsIcons($id, $icon, $color) {
                    &[data-icon="#{$id}"] {
                        >a {
                            &:before {
                                color: $color;
                                content: unicode($icon);
                            }
                        }
                        @if ($id==facebook) or ($id==instagram) or ($id==twitter) or ($id==youtube) {
                            >a {
                                &:before {
                                    font-family: $zmdi;
                                }
                            }
                        }
                    }
                }
                &[data-type="apps"] {
                    >ul {
                        >li {
                            >ul {
                                >li {
                                    @each $id,
                                    $data in $apps-icons {
                                        @include cpbulbaNavAppsIcons($id, nth($data, 1), nth($data, 2));
                                    }
                                }
                            }
                        }
                    }
                }
                &[data-type="media"] {
                    >ul {
                        >li {
                            >ul {
                                >li {
                                    @each $id,
                                    $data in $media-icons {
                                        @include cpbulbaNavAppsIcons($id, nth($data, 1), nth($data, 2));
                                    }
                                    @each $id,
                                    $data in $social-icons {
                                        @include cpbulbaNavAppsIcons($id, nth($data, 1), nth($data, 2));
                                    }
                                }
                            }
                        }
                    }
                }
                &[data-type="aim"] {
                    >ul {
                        position: absolute;
                        box-shadow: 0 1rem 2rem 0 rgba(#000, 0.15);
                        border-bottom-left-radius: 1rem;
                        border-bottom-right-radius: 1rem;
                        overflow: hidden;
                        left: 0;
                        right: 0;
                        top: 3.5em;
                        background: $background-color;
                        padding: 2rem;
                        a {
                            text-transform: none;
                            color: $text-color;
                            &:hover,
                            &:focus {
                                color: $default-color;
                            }
                        }
                        >li {
                            display: flex;
                            box-sizing: border-box;
                            >a {
                                flex: 0;
                                flex-basis: calc(20% - 3rem);
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding: .5rem .5rem .5rem 1rem;
                                border-radius: 2rem;
                                text-transform: uppercase;
                                .submenu-trigger {
                                    font-size: 1.5rem;
                                    line-height: 0;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    transition: all 150ms ease-in-out;
                                    &:before {
                                        content: '\f2f9';
                                        font-family: $zmdi;
                                        width: 2rem;
                                        height: 2rem;
                                        border-radius: 100%;
                                        background: $default-color;
                                        color: #fff;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        transform: rotate(-90deg);
                                    }
                                }
                                &.active {
                                    background: $default-color;
                                    color: #fff;
                                    .submenu-trigger {
                                        &:before {
                                            color: $default-color2;
                                        }
                                    }
                                }
                            }
                            >ul {
                                position: absolute;
                                top: 0%;
                                left: 20%;
                                display: flex;
                                flex-wrap: wrap;
                                width: 80%;
                                padding: 1rem;
                                background: $background-color2;
                                >li {
                                    flex-basis: 20%;
                                    padding: 1rem;
                                    >a {
                                        text-transform: uppercase;
                                    }
                                    >ul {
                                        >li {
                                            >a {
                                                font-weight: $base-font-weight;
                                            }
                                            @mixin cpbulbaNavSecIcons($id, $icon) {
                                                &[data-icon="#{$id}"] {
                                                    >a {
                                                        display: flex;
                                                        align-items: center;
                                                        &:before {
                                                            content: unicode($icon);
                                                            font-family: $cpicons;
                                                            margin-right: .5em;
                                                            font-size: 1.75em;
                                                        }
                                                    }
                                                }
                                            }
                                            @each $id,
                                            $data in $sector-icons {
                                                @include cpbulbaNavSecIcons($id, $data);
                                            }
                                        }
                                    }
                                }
                                &[data-scheme="sectors"] {
                                    @mixin cpbulbaNavSecColours($id, $color1, $color2) {
                                        li[data-color="#{$id}"] {
                                            >a {
                                                color: $color1;
                                            }
                                            >ul {
                                                >li {
                                                    >a {
                                                        &:before {
                                                            color: $color1;
                                                        }
                                                        &:hover,
                                                        &:focus {
                                                            color: $color1;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    @each $id,
                                    $data in $sector-scheme {
                                        @include cpbulbaNavSecColours($id, nth($data, 1), nth($data, 2));
                                    }
                                }
                            }
                            @mixin cpbulbaNavCommunities($id, $color1, $color2) {
                                &[data-color="#{$id}"] {
                                    >a {
                                        .submenu-trigger {
                                            &:before {
                                                background: $color1;
                                            }
                                        }
                                        &.active {
                                            background: $color1;
                                            .submenu-trigger {
                                                &:before {
                                                    color: #fff;
                                                }
                                            }
                                        }
                                    }
                                    >ul {
                                        background: $color1;
                                        a {
                                            color: #fff;
                                            &:focus,
                                            &:hover {
                                                color: $color2;
                                            }
                                        }
                                    }
                                }
                            }
                            @each $id,
                            $data in $groups-scheme {
                                @include cpbulbaNavCommunities($id, nth($data, 1), nth($data, 2));
                            }
                        }
                    }
                }
            }
        }
    }
    &--mobile {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        display: none;
        z-index: 99999;
        @include media("tablet-wide", max) {
            display: block;
        }
        background: $default-color;
        box-shadow: 0 -1rem 2rem 0 rgba(#000, 0.15);
        nav {
            font-family: $heading-font-family;
            font-weight: $heading-font-weight;
            font-size: .75em;
            text-transform: uppercase;
            margin: auto;
            max-width: 480px;
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                height: 4rem;
                display: flex;
                align-items: center;
                li {
                    flex: 1;
                    text-align: center;
                    padding: 0 1.5em;
                    line-height: 4rem;
                    cursor: pointer;
                    color: #fff;
                    &:not(:last-child) {
                        border-right: 1px solid rgba(#fff, 0.25);
                    }
                }
            }
        }
    }
    @media print{
        display: none;
    }
}

.wrapper--body,
.body__wrapper {
    min-height: 83vh;
}


.agent--ipad--12,
.agent--ipad--11,
.agent--ipad--10,
.agent--iphone--12,
.agent--iphone--11,
.agent--iphone--10 {
    body{
        margin-top: 4rem;
    }
    .cpbulba-header {
        &--mobile{
            bottom: auto;
            top: 0;
        }
    }
}