.tooltipster-sidetip {
	&.tooltipster-cp {
        .tooltipster-box {
            border: none;
            border-radius: 1em;
            background: $background-color2;
            box-shadow: 0 1rem 1rem rgba(#000,0.15);
        }
        .tooltipster-content{
            color: $text-color;
            padding: 1em;
            font-size: .85em;
            line-height: 1.5;
        }
		&.tooltipster-bottom {
			.tooltipster-box {
				margin-top: 8px;
			}
			.tooltipster-arrow-border {
				border-bottom-color: $background-color2;
			}
			.tooltipster-arrow-uncropped {
				top: -8px;
			}
		}
		&.tooltipster-left {
			.tooltipster-box {
				margin-right: 8px;
			}
			.tooltipster-arrow-border {
				border-left-color: $background-color2;
			}
		}
		&.tooltipster-right {
			.tooltipster-box {
				margin-left: 8px;
			}
			.tooltipster-arrow-border {
				border-right-color: $background-color2;
			}
			.tooltipster-arrow-uncropped {
				left: -8px;
			}
		}
		&.tooltipster-top {
			.tooltipster-box {
				margin-bottom: 8px;
			}
			.tooltipster-arrow-border {
				border-top-color: $background-color2;
			}
		}
		.tooltipster-arrow {
			height: 8px;
			margin-left: -8px;
			width: 16px;
        }
        &.tooltipster-left,
        &.tooltipster-right{
            .tooltipster-arrow{
                height: 16px;
                margin-left: 0;
                margin-top: -8px;
                width: 8px;
            }
        }
		.tooltipster-arrow-background {
			display: none;
		}
		.tooltipster-arrow-border {
			border: 8px solid transparent;
		}
	}
}
